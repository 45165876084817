.overlay {
    position: absolute;
    border-top: 1px solid;
    width: 100%;
    margin-top: -21px;
    z-index: 1000;
    background-color: #f1f1f18a;
    font-size: x-small;
    height: 20px;
    cursor: pointer;
}

.comment {
    position: absolute;
}

.comment-input > .ant-input-wrapper > .ant-input-group-addon {
    font-size: x-small !important;
}

.comment-input > span > input.ant-input {
    padding: 0 10px !important;
    font-size: x-small !important;
}

.player-container {
    width: 100%;
    height: 100%;
    background-color: #0f00ff1a;
}

@media screen and (min-width: 575px) {
    .player-container {
        height: 75%;
    }
}

.comment-input {
    display: none !important;
}

.overlay {
    display: none;
}

@media screen and (min-width: 575px) {
    .comment-input {
        display: initial !important;
    }

    .overlay {
        display: initial;
    }
}
