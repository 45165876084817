.login-button {
    height: 31px;
    color: white;
    margin: 0 0 0 10px;
    font-weight: bold;
    font-size: large;
    float: right;
    display: none;
}

@media screen and (min-width: 640px) {
    .login-button {
        display: block;
    }
}

.site-title {
    float: left;
    height: 31px;
    color: white;
    margin: 0 0 0 10px;
    font-weight: bold;
    font-size: large;
}

@media screen and (min-width: 640px) {
    .site-banner {
        height: 95vh;
    }
}

.site-banner {
    background-image: url("../img/StockSnap_574OUQY5DK.jpg");
    background-size: auto 100%;
}

.logo {
    float: left;
    width: 31px;
    height: 31px;
    margin: 16px 0 16px 24px;
    background-image: url("../img/avocado_logo.png");
    background-size: 31px;
}
