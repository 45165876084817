@media screen and (min-width: 640px) {
    .site-pitch {
        float: right;
        margin: 30px;
        min-height: 83vh;
        width: 550px;
        background: #bcd3d1;
        padding: 10px;
        border: solid 2px;
        border-radius: 4px;
        border-color: #1e3a39;
    }
}

.site-pitch {
    float: right;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    background: #bcd3d1;
    padding: 10px;
    border: solid 2px;
    border-radius: 4px;
    border-color: #1e3a39;
}
