.site-page-header {
    width: 200px;
    margin-left: 0 !important;
    color: white !important;
}

span.ant-page-header-heading-title {
    font-weight: 400 !important;
    font-size: initial !important;
    margin-right: 0 !important;
}

.ant-page-header-heading-title, .ant-page-header-back-button {
    color: white !important;
}
