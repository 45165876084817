@media screen and (min-width: 575px) {
    .recoding-card>.ant-card-body {
        display: none;
    }

    .ant-card.ant-card-bordered.recoding-card {
        border-top: 0;
    }
}

.ant-card-head-title {
    white-space: pre-line!important;
}
